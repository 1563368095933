import _ from 'lodash';
import React, {useState, useEffect} from 'react' 
import {Button, SidebarModal, WhyAddTheseMarkers} from '../../../components' 
import styles from "./TestUpseller.module.scss"; 
import RenderIf from './../../../components/RenderIf/RenderIf'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faPlus, faTrash } from '@fortawesome/pro-light-svg-icons';

export default function TestUpseller(props) {  
  const [activeTests, setActiveTests] = useState({});
  const [testitem,setTest]=useState({});
  const tests = (props.test?.upsellTests || [])?.filter(test => test.isHealthCoachTest == 0);

  let lowestPrice = _.maxBy(tests, 'testPrice');
  let randomModalString =  Math.floor(Math.random() * 1001);

  const bespokeDomain = new URL(window.config.isDev ? `https://devbuild.${window.config.domain}` : `https://build.${window.config.domain}`)
  let customiseLink = bespokeDomain.href + 'bespoke-test/'+props.testId+'/'+props.phlebotomyOption; 
  let editTestUrl = bespokeDomain.href + "edit-test/"+props.testId+"/"+props.phlebotomyOption;
  let plusTestsArray = [];
  let chatTest = 0;

  const voucherCode = props.voucher.voucherCode

  for (let i = 0; i < tests.length; i++){ 
    if (activeTests[tests[i].testId]) {
      plusTestsArray.push(parseInt(tests[i].testId));
    }
  }

  if (plusTestsArray.length > 0) {
    customiseLink +='/'+JSON.stringify(plusTestsArray);
  } else {
    customiseLink += '/[]'
  }
  
  if(voucherCode){
    customiseLink += `/${chatTest}/${voucherCode}`
    editTestUrl += `/${voucherCode}`
  } else {
    customiseLink += `/${chatTest}/0`
  }

  const partnerId = localStorage.getItem('partnerPortalId');
  if(global.config.websiteId > 2 && partnerId){
    customiseLink += `/${partnerId}`
  }


  const toggleActiveTest = (testId) => {
    if (props.quantity != 1) {
      props.handleQuantityChange(props.testId, 1);
    } 

    let testArray = activeTests;
    testArray[testId] = !activeTests[testId];
 
    if (Object.values(testArray).every((v) => v === false)) {
      props.disableQuantity(false);
    } else {
      props.disableQuantity(true);
    }
    
    setActiveTests({...testArray})
    props.setPlusTests(props.testId, activeTests);
  }

  const sendMessage = () => {
    if(window.parent) {
      window.parent.postMessage('goToCheckout', '*');
    }
  }
 
  if(props.showEdit != 1 && tests?.length == 0) {
    return null
  }

  return (

    <div className="row mt-3 mb-5">
      <div className="col-12 px-3">
        <div id={styles.testUpseller}>
          {props.showEdit != 1 ? (
            <div className="row justify-content-center align-items-center">
              <div className="col-12 text-center">
                {props.test.upsellTests.length > 0 ? <h2 className="mb-2">Frequently purchased together</h2> : <h2 className="">Customise this test</h2>}
              </div> 
              <div className="col-12">
                <div className="row">
                  {tests.map(test =>
                    <div key={test.testId} className="col-12 col-lg-6 mt-2 mt-lg-0">
                      <div className={activeTests[test.testId] ? styles.testItem+' '+styles.active : styles.testItem} onClick={e => toggleActiveTest(test.testId)} >
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className={"me-3 "+styles.checkBoxCircle}>
                              {activeTests[test.testId] ? <FontAwesomeIcon icon={faCheck} /> : ''}
                            </div>
                            <div className="d-flex align-items-center">
                              <h5 className="m-0" dangerouslySetInnerHTML={{__html: test.testName}}></h5>
                              <div className={styles.price+" ms-1"}>(<span>£</span>{Math.round(test.testPrice)})</div>
                            </div>
                          </div>

                          <div className="d-flex align-items-center justify-content-between col-auto">
                            <div className="cross">
                              <FontAwesomeIcon icon={activeTests[test.testId] ? faTrash : faPlus} />
                            </div> 
                          </div>
                        </div> 
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 mt-2 text-center">

                <RenderIf isTrue={props.test.upsellTests.length > 0 && props.relevantMarkersReason != ''}>
                  <div className={"me-2 mb-3 "+styles.modalToggler} data-bs-toggle="modal" data-bs-target={"#markerInfo"+randomModalString}>
                    Why should I add these?  
                  </div>
                </RenderIf>

                <p className="mb-2">Save money when adding extra markers to this test.</p>
                
                <SidebarModal modalId={"markerInfo"+randomModalString}>
                  <WhyAddTheseMarkers test={props.test} tests={tests} />
                </SidebarModal>
                
                {props.showEdit == 1 ? (
                  <div className="text-center">
                    <Button onClick={() => { sendMessage() }} href={"/edit-test/"+props.testId+"/"+props.phlebotomyOption} className="w-100 d-block" variant="primary-outline">Modify your test</Button>
                  </div>
                ) : (
                  <div className="text-center">
                    <Button onClick={() => { sendMessage() }} href={customiseLink} className="w-100 d-block" variant="primary-outline">Add more markers</Button>
                  </div>
                )} 
              </div>
            </div> 
          ) : (
            <div className="row justify-content-center align-items-center">
              <div className="col-12 text-center">
                <h2 className={styles.modifyTest}><img className={styles.upsellImage} src={global.config.websiteId == 1 ?  "/img/boxIcon.png" : "/img/boxIconEdge.png"}/>{props.test.bespokeTest ? " Modify your test" : " Want more health markers?"}</h2>
              </div>  
              <div className="col-12 mt-4 text-center">
                <p className="mb-2">Add extra biomarkers to this test from as <strong>little as £7</strong> to get a better understanding of your hormone levels. </p>
                
                <div className="text-center">
                  <Button onClick={() => { sendMessage() }} href={editTestUrl} className="w-100 d-block" variant="primary-outline">Add more markers</Button>
                </div>
              </div>
            </div>
          )} 
          
        </div>
      </div>
    </div>
  )
 

}