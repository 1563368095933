import {useState, useEffect, useRef, useMemo} from 'react'; 
import RenderIf from '../../../RenderIf/RenderIf';
import LoadingSpinner from '../../LoadingSpinner';

import styles from './inputCheckbox.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
const InputCheckbox = ({label, labelClassName, name, id, className, isLoading, required, value, onChange, ...rest}) => {

  return (
    <> 
      <div onClick={()=> {onChange()}}  className={`${(value) ? `${styles.styledCheckbox} ${styles.active}` : styles.styledCheckbox} ${className}`}>
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center justify-content-between">
            <div className={`me-3 ${styles.checkBoxCircle}`}
            name={name} 
            id={id} 
            required={required}  
            {...rest}> 
              {value == true ? <FontAwesomeIcon icon={faCheck} /> : ''}
            </div>
            <div class="d-flex">
              <p class="m-0">{label}</p>
              <RenderIf isTrue={isLoading}>
                <div className={`${styles.spinnerContainer}`}>
                  <LoadingSpinner/>
                </div>
              </RenderIf>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
 

export default InputCheckbox