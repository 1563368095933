import React, {useState, useEffect} from 'react'
import { useDispatch } from "react-redux";
import _ from 'lodash'; 
import {BasketItems, CartSummary, CheckoutProgressBar, YourOrderWrapper, UserDetails, AddressForm, Alert, Button, PostCodeLookUp, DeliveryForm} from '../../../components'
import RenderIf from '../../RenderIf/RenderIf';
import calculateBasket from "../../../helpers/calculateBasket"
import formValidator from '../../../helpers/formValidator'; 
import styles from "./CheckoutStepThree.module.scss"; 
import base64 from 'base-64'; 
import runGA from '../../../helpers/runGA';
import { getPartnerPortalId } from '../../../utils';
import moment from 'moment';


const INITIAL_HRT_SELECTED_VALUE = {
  3: false,
  4: false,
  5: false
}

const isBiomarkerTracking = global.config.websiteId != 1 && global.config.websiteId != 2

export default function CheckoutStepThree(props) {  
  const [subtotal, setSubtotal] = useState();
  const [basketTotal, setBasketTotal] = useState();
  const [errorMessage, setErrorMessage] = useState("")
  const [showAddressArea, setShowAddressArea] = useState(false);
  const [error, setError] = useState(false);
  const [initialAddress, setInitialAddress] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(0)
  const [isFirstSubmit, setIsFirstSubmit] = useState(0)
  const [newAccount, setNewAccount] = useState(false);
  const [address, setAddress] = useState({country: "United Kingdom"});
  const [response, setResponse] = useState({});
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false)
  const [testPrice, setTestPrice] = useState();

  const dispatch = useDispatch()

  useEffect(() => { 
    if (props.guestCheckout && !props.checkoutFromPartner) {
      dispatch(props.logout())
    }
  },[]);
 
  useEffect(() => { 
    getValues();  
  },[props]);

  const partner = props.partner;
  
  let guestCheckout = props.guestCheckout;
  let partnerPortalId = getPartnerPortalId(partner)


  if (props.guestCheckout == 'unset') {
    guestCheckout = false;
  }

  useEffect(() => { 
    if (!error && formSubmitted > 0) {
      let updatePayload;

      if (guestCheckout) {
        updatePayload = {
          customerFirstName: props.customerDetails.customerFirstName,
          customerLastName: props.customerDetails.customerLastName,
          customerEmailAddress: document.getElementById("customerEmailAddress").value,
          partnerPortalId,
          customerAddress1: `${address.houseNumber}, ${address.address1}`, 
          customerAddress2: address.address2,
          customerAddress3: address.address3,
          customerCity: address.town,
          customerPostcode: address.postcode,
          customerCountry: address.country,
          orderCookieId: localStorage.getItem("orderCookieId"),
          guestCustomerId: props.guestCustomerId,
        };
        props.setCustomerDetails(updatePayload) 
      } else {
        updatePayload = {
          customerFirstName: props.customerDetails.customerFirstName,
          customerLastName: props.customerDetails.customerLastName,
          customerEmailAddress: props.customerDetails.customerEmailAddress,
          customerHomeTelephone: props.customerDetails.customerHomeTelephone,
          customerDOBD: props.customerDetails.customerDOBD,
          customerDOBM: props.customerDetails.customerDOBM,
          customerDOBY: (props.customerDetails.customerDOBY).toString(),
          customerSex: props.customerDetails.customerSex,
          customerEthnicity: props.customerDetails.customerEthnicity,
          customerMenopausal: props.customerDetails.customerMenopausal,
          customerWeight: props.user.customerWeightKG ? props.user.customerWeightKG : 0,
          customerWeightST: props.user.customerWeightST ? props.user.customerWeightST : 0,
          customerWeightIB: props.user.customerWeightIB ? props.user.customerWeightIB : 0,
          customerWeightType: props.user.customerWeightType || 1,
          customerHeight: props.user.customerHeightM ? props.user.customerHeightM : 0,
          customerHeightFT: props.user.customerHeightFT ? props.user.customerHeightFT : 0,
          customerHeightIN: props.user.customerHeightIN ? props.user.customerHeightIN : 0,
          customerHeightType: props.user.customerHeightType || 1,
          customerAddress1: props.customerDetails.customerAddress1,
          customerAddress2: props.customerDetails.customerAddress2,
          customerAddress3: props.customerDetails.customerAddress3,
          customerAddress4: props.user.customerAddress4 ? props.user.customerAddress4 : "",
          customerCity: props.customerDetails.customerCity,
          customerCountry: props.customerDetails.customerCountry,
          customerPostcode: props.customerDetails.customerPostcode,
          customerOptIn: props.customerDetails.customerOptIn,
          customerMainGoal: props.customerDetails.customerMainGoal || 0,
          customerExercise: props.user.customerExerciseId || 0,
          customerPrimarySport: props.user.customerPrimarySport || 0,
          customerOftenExercise: props.user.customerOftenExercise || 0,
          customerTraining: props.user.customerTraining ? props.user.customerTraining : "",
          customerHealthyDiet: props.user.healthyDietTypeId || 0,
          customerDiet: props.user.dietTypeId ? props.user.dietTypeId : 0,
          customerAlcoholUnitsId: props.user.customerAlcoholUnitsId || 0,
          customerSupliments: props.user.customerSuplimentsId || 0,
          customerSuplimentsDetails: props.user.customerSuplimentsDetails || "",
          customerSuffer: props.user.customerSufferId ? props.user.customerSufferId : 0,
          customerSufferDetails: props.user.customerSufferDetails || 0,
          customerRegularPeriods: props.customerDetails.customerMenopausal == 2 ? 0 : props.customerDetails.customerRegularPeriods,
          customerAverageCycleLength: props.customerDetails.customerAverageCycleLength || "",
          customerContraception: props.customerDetails.customerMenopausal == 2 ? 0 : props.customerDetails.customerContraception,
          customerContraceptionSelected: props.customerDetails.customerMenopausal == 2 ? "" : props.customerDetails.customerContraceptionSelected || "",
          customerContraceptionTypeOther: props.user.customerContraceptionTypeOther || "",
          customerIsPregnant: props.customerDetails.customerMenopausal == 2 ? 0 : props.customerDetails.customerIsPregnant,
          customerIsBreastfeeding: props.customerDetails.customerMenopausal == 2 ? 0 : props.customerDetails.customerIsBreastfeeding || 0,
          customerHRT: props.customerDetails.customerMenopausal == 1 ? 0 : props.customerDetails.customerHRT,
          customerHRTSelected: props.customerDetails.customerMenopausal == 1 ? INITIAL_HRT_SELECTED_VALUE : props.customerDetails.customerHRTSelected,
          customerAdditionalMedicalInfo: props.user.customerAdditionalMedicalInfo || "",
          guestCustomerId: props.user.iD,
          orderCookieId: localStorage.getItem("orderCookieId"),
          doNotReview: props.customerDetails.doNotReview,
          overideAndAllowFHM: props.user.overideAndAllowFHM,
          checkoutFromPartner: props.customerDetails.checkoutFromPartner,
          customerId: props.customerDetails.checkoutFromPartner == 1 ? props.customerDetails.userId : undefined,
          grantPartnerPermission: 1,
          partnerPortalId,
          customerTransitioning: props.customerDetails.customerTransitioning || 0,
          customerSexIdentify: props.customerDetails.customerSexIdentify || 0,
          customerTransitioningMedication: props.customerDetails.customerTransitioningMedication || "",
        };
      } 
      
      if(props.checkoutFromPartner == 1){
        updatePayload = {
          ...updatePayload,
          customerId: localStorage.getItem("customerId"),
          checkoutFromPartner: 1
        }
      }

      if ((props.authentication.isAuthenticated != true && (guestCheckout == false || guestCheckout == 'unset') && props.checkoutFromPartner == 0)) {
        let payload = {
          customerEmailAddress: props.customerDetails.customerEmailAddress,
          customerPassword: props.customerDetails.customerPassword,
          orderCookieId: localStorage.getItem("orderCookieId"),
          guestCheckout: false,
        };

        props.loadCreateAccount(payload).then((response) => {
          if (response.error) { 
            setError(true);
            setErrorMessage(response.error);   
          } else {  
            if (!error) { 
              setError(false);
              setErrorMessage(''); 

              updatePayload = {
                ...updatePayload,
                guestCustomerId: response.response.newCustomerId
              }

              props.loadToken(props.customerDetails.customerEmailAddress, props.customerDetails.customerPassword, '', global.config.websiteId, 'CO')
              .then((response) => {
                if (response.error) {
                  setError(true); 
                  setErrorMessage(response.error);  
                } else {  
                  setError(false); 
                  setErrorMessage('');  

                  localStorage.setItem('token', response.response.token);

                  props.updateUserProfile(updatePayload).then((response) => {
                    if (response.error) {
                      setError(true);  
                      setErrorMessage(response.error); 
                    } else {
                      setError(false);  
                      setErrorMessage(''); 

                      let partnerId;
                      if (Object.keys(partner).length > 0) {
                        partnerId = partner[0].partnerId;
                      } 

                      props.generateReferCode().finally(() => {
                        props.loadUser('me').then((response) => { 
                          props.reloadUserData();
                          setError(false); 
                          setErrorMessage('');  
                          if (!response.error && response.response.partnerId != 0){
                            props.loadPartnerDetailsUnsecure(response.response.partnerId).then(()=>{     
                              if(isBiomarkerTracking) {
                                localStorage.setItem('partnerPortalId', response.response.partnerId);  
                              } else {
                                localStorage.setItem('partnerPortalId', 0)
                              }
                            })
                          }
                          props.goToStep(3); 
                          runGA("/checkout/delivery-details");
                        });
                      })
                      

                    }
                  });

                  if(props.foundEndoTest) {
                    props.submitEndoAnswer()
                  }
                }
              }); 
            }
          }
        });

        
      } else {
        props.updateUserProfile(updatePayload).then((response) => {
          if (response.error) {
            setError(true);  
            setErrorMessage(response.error); 
          } else {
            setError(false);  
            setErrorMessage(''); 

            let partnerId;
            if (Object.keys(props.partner).length > 0) {
              partnerId = partner[0].partnerId;
            } 
            
            props.loadUser('me').then((response) => { 
              props.reloadUserData();
              setError(false); 
              setErrorMessage('');  
              if (!response.error && response.response.partnerId != 0){
                props.loadPartnerDetailsUnsecure(response.response.partnerId).then(()=>{     
                  if(isBiomarkerTracking) {
                    localStorage.setItem('partnerPortalId', response.response.partnerId);  
                  } else {
                    localStorage.setItem('partnerPortalId', 0)
                  }     
                })
              }
              if (props.guestCheckout && !props.checkoutFromPartner) {
                props.goToStep(4);
                props.handleTracking(3)
                runGA("/checkout/payment"); 
              } else {
                props.goToStep(3);
                runGA("/checkout/delivery-details"); 
              }
            });
          }
        });

        if(props.foundEndoTest) {
          props.submitEndoAnswer()
        }
      }  
    }

  }, [formSubmitted, isFirstSubmit]);

  useEffect(()=>{
    if (!error && Object.keys(response).length > 0) {

      setError(false);
      setErrorMessage(''); 
      props.setCanEnterDetails(true);
      props.setGuestCustomerId(response.response.newCustomerId)
      props.setCustomerDetails({
        ...props.customerDetails,
        customerEmailAddress:  document.getElementById("customerEmailAddress").value,
        guestCustomerId: response.response.newCustomerId,
      })    
      let cart = props.cart[0].basketArray;
      let newCart = [];
      Object.keys(cart).map(function(key) {
        newCart.push(cart[key][0] + ":0");
      });

      let newUrl = base64.encode(newCart.toString());

      let items = props.basketTestItems;
      let itemArray = [];
      Object.keys(items).map(function(key) {
        itemArray.push(items[key]);
      });

      if (global.config.websiteId == 1 || global.config.websiteId == 2) {
        let website = "shop.sportsbloodtests.co.uk";
        if (global.config.websiteId == 1) {
          website = "shop.forthwithlife.co.uk";
        }
        //SEND IN BLUE TRACKING TO GO HERE
      }
      setFormSubmitted(formSubmitted+1)
    }

  }, [response])

  const submitUserForm = (e) => {
    e.preventDefault(); 
    setError(false);
    setErrorMessage("")
    setIsFirstSubmit(isFirstSubmit+1)

    if (!props.checkoutFromPartner && document.getElementById("customerEmailAddress").value != '' && formValidator(document.getElementById("customerEmailAddress").value, 'email') == true) {
      const hasNumber = /\d/; 
      const hasSpecial = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      setErrorMessage(""); 
      if((props.authentication.isAuthenticated != true && (guestCheckout == false || guestCheckout == 'unset')) && props.checkoutFromPartner == 0){
        if(props.customerDetails.customerPassword != "" && hasNumber.test(props.customerDetails.customerPassword) && hasSpecial.test(props.customerDetails.customerPassword) && props.customerDetails.customerPassword?.length >= 8){
          setError(false)
          setErrorMessage('')
          //return
        } else {
          setError(true);
          setErrorMessage("Your password must contain at least 1 number and 1 special character, and at least eight characters") 
          props.setCustomerDetails({...props.customerDetails, customerPassword: ""})
          setIsNextButtonDisabled(false)
          //return
        }
      }

      checkAgeLimit();


      if(!props.customerDetails?.customerFirstName){
        setError(true);
        setErrorMessage("Please, enter your first name") 
        setIsNextButtonDisabled(false)
        props.setCustomerDetails({...props.customerDetails, customerFirstName: ""})
        return
      }

      if(!props.customerDetails?.customerLastName){
        setError(true);
        setErrorMessage("Please, enter your last name") 
        setIsNextButtonDisabled(false) 
        props.setCustomerDetails({...props.customerDetails, customerLastName: ""})
        return
      }
      


      if (!guestCheckout) {
        if(!props.customerDetails?.customerHomeTelephone){
          setError(true);
          setErrorMessage("Please, enter your phone number")  
          setIsNextButtonDisabled(false)
          props.setCustomerDetails({...props.customerDetails, customerHomeTelephone: ""})
          return
        }

        if(!props.customerDetails.customerDOBD || !props.customerDetails.customerDOBM || !props.customerDetails.customerDOBY) {
          setError(true);
          setErrorMessage("Please enter your date of birth")  
          setIsNextButtonDisabled(false)
          props.setCustomerDetails({...props.customerDetails, customerDOBD: "", customerDOBM: "", customerDOBY: ""})
          return
        }

        if(props.customerDetails.customerDOBD && props.customerDetails.customerDOBM && props.customerDetails.customerDOBY) {
          const fromDOB = moment().subtract(18, "years").format("YYYY/MM/DD")
          const customerDOB = moment(`${props.customerDetails.customerDOBY}/${props.customerDetails.customerDOBM}/${props.customerDetails.customerDOBD}`).format("YYYY/MM/DD")
          const difference = moment(customerDOB).diff(fromDOB, 'days');

          if (difference > 0) {
            setError(true);
            setErrorMessage("You must be at least 18 years old")  
            setIsNextButtonDisabled(false)
            props.setCustomerDetails({...props.customerDetails, customerDOBD: "", customerDOBM: "", customerDOBY: ""})
            return
          }
        }

        if(!props.customerDetails.customerEthnicity || props.customerDetails.customerEthnicity == 0){
          setError(true);
          if(props.checkoutFromPartner == 1){
            setErrorMessage("Please enter your client's ethnicity")  
          }else{
            setErrorMessage("Please enter your ethnicity")  
          }
          if(!props.customerDetails.customerEthnicity){
            props.setCustomerDetails({...props.customerDetails, customerEthnicity: ""})
          }
          setIsNextButtonDisabled(false)
          return
        }

        if(!props.customerDetails.customerSex){
          setError(true);
          setErrorMessage("Please select your biological sex at birth")
          setIsNextButtonDisabled(false)
          props.setCustomerDetails({...props.customerDetails, customerSex: ""}) 
          return
        }

        if (props.customerDetails.customerSex == 2) {
          if (props.customerDetails.customerMenopausal == 0 || props.customerDetails.customerMenopausal == "0" || props.customerDetails.customerMenopausal == "") {
            setError(true);
            setErrorMessage("Please tell us if you describe yourself as post-menopausal") 
            setIsNextButtonDisabled(false)
            if(!props.customerDetails.customerMenopausal){
              props.setCustomerDetails({...props.customerDetails, customerMenopausal: ""})
            }
            return
          }

          if (props.customerDetails.customerMenopausal == 2) {
            if(props.customerDetails.customerHRT == 0 || props.customerDetails.customerHRT == "0" || props.customerDetails.customerHRT == "") {
              setError(true);
              setErrorMessage("Please tell is if you are on HRT")
              setIsNextButtonDisabled(false)
              if(!props.customerDetails.customerHRT){
                props.setCustomerDetails({...props.customerDetails, customerHRT: ""})
              }
              return
            }

            if(props.customerDetails.customerHRT == 2){
              const hasHRTSelected = Object.values(props.customerDetails.customerHRTSelected).some( item => item == true)
              if(!hasHRTSelected){
                setError(true);
                setErrorMessage("Please enter what type of HRT you are") 
                setIsNextButtonDisabled(false) 
                return
              }
            }
          }

          if (props.customerDetails.customerMenopausal == 1) {
            if (props.customerDetails.customerContraception == 0 || props.customerDetails.customerContraception == "0" || props.customerDetails.customerContraception == "") {
              setError(true);
              setErrorMessage("Please tell us if you are using contraception")
              setIsNextButtonDisabled(false)
              if(!props.customerDetails.customerContraception){
                props.setCustomerDetails({...props.customerDetails, customerContraception: ""})
              }  
              return
            }
            if (props.customerDetails.customerContraception == 1) {
              if (props.customerDetails.customerRegularPeriods == 0 || props.customerDetails.customerContraception == "0" || props.customerDetails.customerContraception == "") {
                setError(true);
                setErrorMessage("Please tell us if you are having regular periods")
                setIsNextButtonDisabled(false)
                if(!props.customerDetails.customerRegularPeriods){
                  props.setCustomerDetails({...props.customerDetails, customerRegularPeriods: ""})
                }   
                return
              }
            }
            if (props.customerDetails.customerContraception == 2 && !props.customerDetails.customerContraception){
              setError(true);
              setErrorMessage("Please tell us what form of hormonal contraception you are taking") 
              setIsNextButtonDisabled(false)
              if(!props.customerDetails.customerContraceptionSelected){
                props.setCustomerDetails({...props.customerDetails, customerContraceptionSelected: ""})
              }  
              return
            }
          }
        }
      

        if(!props.customerDetails?.doNotReview && props.customerDetails?.doNotReview == undefined){
          setError(true);
          setErrorMessage("Please, enter if you would like a Forth doctor comment on you results.")
          setIsNextButtonDisabled(false)
          props.setCustomerDetails({...props.customerDetails, doNotReview: undefined})
          return  
        }
        setFormSubmitted(formSubmitted+1)

      } else {
        // Do guest stuff here

        if(!address.houseNumber){
          setErrorMessage("Please enter the house name or number")
          setError(true)
          setIsNextButtonDisabled(false)
          return
        }
      
        if(!address.address1){
          setError(true)
          setErrorMessage("Please enter the Address line 1")
          setIsNextButtonDisabled(false)
          return
        }
      
        if(!address.town){
          setError(true)
          setErrorMessage("Please enter the Town")
          setIsNextButtonDisabled(false)
          return
        }
      
        if(!address.postcode){
          setError(true)
          setErrorMessage("Please enter the Postcode")
          setIsNextButtonDisabled(false)
          return
        }
      
        if(!address.country){
          setError(true)
          setErrorMessage("Please enter the Country")
          setIsNextButtonDisabled(false)
          return
        }

        let payload = {
          customerEmailAddress: document.getElementById("customerEmailAddress").value,
          customerPassword:
            Math.random()
              .toString(36)
              .substring(2, 15) +
            Math.random()
              .toString(36)
              .substring(2, 15),
          orderCookieId: localStorage.getItem("orderCookieId"),
          guestCheckout: true,
        };
  
        props.loadCreateAccount(payload).then((response) => {
          if (response.error) { 
            setError(true);
            setErrorMessage(response.error); 
            props.setCanEnterDetails(false);  
          } else {
            setResponse(response)
            setError(false);
          }
        });
      }
    } else if (!props.checkoutFromPartner){
      setError(true); 
      setErrorMessage("Please enter a valid email address")  
      setIsNextButtonDisabled(false)
    } else {
      setFormSubmitted(formSubmitted+1)
    }
  }

  const getValues = () => {
    let cartProps = {
      "user": props.user,
      "cart": props.cart[0],
      "tests": props.basketTestItems,
      "basket": props.cart[0].basketArray,
      "phlebotomyOption": props.phlebotomyOption,
      "stateVoucher": localStorage.getItem('voucher'),
      "voucher": props.voucher,
      "partner": props.partner,
      "phlebotomyPrices": props.phlebotomyPrices,
      "subscriptionOption": props.subscriptionOption,
      "globalPlusTests": props.globalPlusTests,
      "globalHCtests": props.globalHCTests,
      "guestCheckout": props.guestCheckout,
      "deliveryOption": props.deliveryOption,
      "deliveryInfo": props.deliveryInfo,
      "nextDayDeliveryPrice": props.nextDayDeliveryPrice,
      "step": props.step
    } 
    
    let checkoutTotal = calculateBasket(cartProps); 
    setSubtotal(checkoutTotal.subtotal);
    setBasketTotal(checkoutTotal.testPrice);
    setTestPrice(checkoutTotal.testPrice);
  }

  const handleReturnInitialAddress = (address) => {
    setInitialAddress({...address})
    setShowAddressArea(true)
  }

  const checkAgeLimit = () => {
    let tests = props.basketTestItems;
    let minAgeLimit = 18;
    let maxAgeLimit = 125;
    let ageAlert; 
    Object.keys(tests).map(function(key) { 
      minAgeLimit = tests[key].minAgeLimit;
      maxAgeLimit = tests[key].maxAgeLimit;
      if (tests[key].minAgeLimit != 18 || tests[key].maxAgeLimit != 125){
        ageAlert = "You must be ";
        if (tests[key].minAgeLimit != 18) {
          ageAlert += "over " + tests[key].minAgeLimit + " ";
        }
        if (tests[key].minAgeLimit != 18 && tests[key].maxAgeLimit != 125) {
          ageAlert += "and ";
        } 
        if (tests[key].maxAgeLimit != 125) {
          ageAlert += "under " + tests[key].maxAgeLimit + " ";
        }
        ageAlert += "to preform this test";
      } 
    }); 

    if (ageAlert) {
      const date = moment(); 	
      setError(true);  
      setErrorMessage(ageAlert);  
      let years = moment().diff(props.customerDetails.customerDOBY+'-'+props.customerDetails.customerDOBM+'-'+props.customerDetails.customerDOBD, 'years');
      if (parseInt(years) < minAgeLimit || parseInt(years) > maxAgeLimit) {
        setError(true);  
        setErrorMessage(ageAlert);  
        setIsNextButtonDisabled(false)
        return
      } else { 
        setError(false);  
        setErrorMessage('');  
      }
    }
     
  }
    
  return ( 
    <>
      <div className="row d-block d-md-none pt-0 pt-md-5 g-0 w-100">
        <div className="col-12">
          <YourOrderWrapper subtotal={subtotal} totalAmount={parseFloat(testPrice)} partner={props.partner} checkoutFromPartner={props.checkoutFromPartner} step={props.step} >
            <BasketItems
              cart={props.cart}
              basketTestItems={props.basketTestItems}
              phlebotomyOption={props.phlebotomyOption}
              getVoucher={props.getVoucher}
              globalPlusTests={props.globalPlusTests}
              setPlusTests={props.setPlusTests}
              handleQuantityChange={props.handleQuantityChange}
              subscriptionInfo={props.subscriptionInfo}
              subscriptionOption={props.subscriptionOption}
              step={props.step} 
              partner={props.partner} 
              voucher={props.voucher} 
              handleSubscriptionChange={props.handleSubscriptionChange}
              globalHCTests={props.globalHCTests}
              setGlobalHCTests={props.setGlobalHCTests}
            />
            <CartSummary 
              onlySellPhlebotomyKits={props.onlySellPhlebotomyKits}
              deliveryInfo={props.deliveryInfo}
              customerDetails={props.customerDetails}
              phlebotomyPrices={props.phlebotomyPrices}
              voucherRemovedBecauseOfCredit={props.voucherRemovedBecauseOfCredit}
              subscriptionOption={props.subscriptionOption}
              step={props.step}
              globalPlusTests={props.globalPlusTests}
              deliveryOption={props.deliveryOption}
              guestCheckout={props.guestCheckout}
              subscriptionInfo={props.subscriptionInfo}
              cart={props.cart[0]}
              basketTestItems={props.basketTestItems}
              basketArray={props.cart[0].basketArray}
              phlebotomyOption={props.phlebotomyOption}
              voucher={props.voucher}
              user={props.user}
              partner={props.partner}
              nextDayDeliveryPrice={props.nextDayDeliveryPrice}
              partnerClinics={props.partnerClinics}
              basket={props.basket}
              tests={props.basketTestItems}
              updatePhlebotomyOption={props.updatePhlebotomyOption}
              phlebotomyError={props.phlebotomyError}
              updateDeliveryOption={props.updateDeliveryOption} 
              allowInternational={props?.stateVoucher?.allowInternational}
              globalHCTests={props.globalHCTests}
            /> 
          </YourOrderWrapper>
             
        </div>
      </div>
      <div className="container py-5">
        <div className="row">  
          <div className="col-12 col-md-6">
            <CheckoutProgressBar showQuestionAdditionalStep={props.foundEndoTest || props.foundTrueCheckTest} goToStep={props.goToStep}  step={props.step} checkoutFromPartner={props.checkoutFromPartner} skipStepOne={props.skipStepOne} guestCheckout={props.guestCheckout}/> 
            <UserDetails 
              isLoadingSmall={props.isLoadingSmall}
              customerDetails={props.customerDetails}
              setCustomerDetails={props.setCustomerDetails}
              canEnterDetails={props.canEnterDetails}
              setCanEnterDetails={props.setCanEnterDetails}
              guestCheckout={props.guestCheckout}
              loadToken={props.loadToken}
              loadUser={props.loadUser}
              reloadUserData={props.reloadUserData}
              loadPartnerDetailsUnsecure={props.loadPartnerDetailsUnsecure}
              setGuestCustomerId={props.setGuestCustomerId}
              guestCustomerId={props.guestCustomerId}
              cart={props.cart}
              basketTestItems={props.basketTestItems}
              authentication={props.authentication}
              loadCreateAccount={props.loadCreateAccount}
              checkEmailExist={props.checkEmailExist}
              checkoutFromPartner={props.checkoutFromPartner}
              voucher={props.voucher}
              goToStep={props.goToStep}
              partner={props.partner}
              user={props.user}
              updateUserProfile={props.updateUserProfile}
              clientEmailsCheck={props.clientEmailsCheck}
              receiptEmailAddress={props.receiptEmailAddress}
              setClientEmailsCheck={props.setClientEmailsCheck}
              setReceiptEmailAddress={props.setReceiptEmailAddress}
              setPartner={props.setPartner}
              setGrantPartnerPermission={props.setGrantPartnerPermission}
              grantPartnerPermission={1}
              setPermissionChanged={props.setPermissionChanged}
              isFirstSubmit={isFirstSubmit}
              generateReferCode={props.generateReferCode}
              foundTrueCheckTest={props.foundTrueCheckTest}
            />  
            <RenderIf isTrue={props.guestCheckout && !props.checkoutFromPartner}>
              <DeliveryForm voucher={props.voucher} address={address} setAddress={setAddress} customerDetails={props.customerDetails}/> 
            </RenderIf>

            {error && (  
              <div className="col-md-12 mt-3">
                <div className="alert alert-danger basketAlert" role="alert">{errorMessage}</div> 
              </div> 
            )}

            <div className="col-12" align="center">
              {props.guestCheckout && !props.checkoutFromPartner ? (  
                <Button type="submit" onClick={(e)=>{setIsNextButtonDisabled(true); submitUserForm(e)}} disabled={(props.canEnterDetails && !isNextButtonDisabled) ? false : true} className={`mt-2 mb-3 w-100`}>
                  Next - Payment
                </Button>
              ) : (
                <Button type="submit" onClick={(e)=>{setIsNextButtonDisabled(true); submitUserForm(e)}} disabled={(props.canEnterDetails && !isNextButtonDisabled) ? false : true} className={`mt-2 mb-3 w-100`}>
                  Next - Delivery Details
                </Button>
              )}
            </div>
          </div>

          <div className="col-12 col-md-6 d-none d-md-block"> 
            <YourOrderWrapper>
              <BasketItems
                cart={props.cart}
                basketTestItems={props.basketTestItems}
                phlebotomyOption={props.phlebotomyOption}
                getVoucher={props.getVoucher}
                globalPlusTests={props.globalPlusTests}
                setPlusTests={props.setPlusTests}
                handleQuantityChange={props.handleQuantityChange}
                subscriptionInfo={props.subscriptionInfo}
                subscriptionOption={props.subscriptionOption}
                step={props.step} 
                partner={props.partner} 
                voucher={props.voucher} 
                handleSubscriptionChange={props.handleSubscriptionChange}
                globalHCTests={props.globalHCTests}
                setGlobalHCTests={props.setGlobalHCTests}
              />
              <CartSummary 
                onlySellPhlebotomyKits={props.onlySellPhlebotomyKits}
                deliveryInfo={props.deliveryInfo}
                customerDetails={props.customerDetails}
                phlebotomyPrices={props.phlebotomyPrices}
                voucherRemovedBecauseOfCredit={props.voucherRemovedBecauseOfCredit}
                subscriptionOption={props.subscriptionOption}
                step={props.step}
                globalPlusTests={props.globalPlusTests}
                deliveryOption={props.deliveryOption}
                guestCheckout={props.guestCheckout}
                subscriptionInfo={props.subscriptionInfo}
                cart={props.cart[0]}
                basketTestItems={props.basketTestItems}
                basketArray={props.cart[0].basketArray}
                phlebotomyOption={props.phlebotomyOption}
                voucher={props.voucher}
                user={props.user}
                partner={props.partner}
                nextDayDeliveryPrice={props.nextDayDeliveryPrice}
                partnerClinics={props.partnerClinics}
                basket={props.basket}
                tests={props.basketTestItems}
                updatePhlebotomyOption={props.updatePhlebotomyOption}
                phlebotomyError={props.phlebotomyError}
                updateDeliveryOption={props.updateDeliveryOption} 
                allowInternational={props?.stateVoucher?.allowInternational}
                globalHCTests={props.globalHCTests}
              /> 
            </YourOrderWrapper> 
          </div>
        </div>
      </div> 
    </>   
  )
  
}