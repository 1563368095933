import React, {useState, useRef, useMemo} from 'react'
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import Geocode from "react-geocode";

import Button from '../../GlobalComponents/Button';
import RenderIf from '../../RenderIf/RenderIf';

import styles from "./PartnerClinicFinder.module.scss"; 
import ProviderPill from './ProviderPill/ProviderPill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faLocationArrow } from '@fortawesome/pro-regular-svg-icons';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

Geocode.setApiKey("AIzaSyDE6jdIQJ3Vcm4-4pz1mTM39ZDVG-SJ5gQ");
Geocode.setLanguage("en"); 
Geocode.setRegion("gb");

const isForth = global.config.websiteId == 1
const isEdge = global.config.websiteId == 2

const PartnerClinicFinderInline = (props) => {   
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [activeMarker, setActiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});
  const [useCurrentLocation, setUseCurrentLocation] = useState(false);
  const [position, setPosition] = useState(null);
  const [longitudeSearch, setLongitudeSearch] = useState('-2.43');
  const [latitudeSearch, setLatitudeSearch] = useState('53.34');
  const [displayAll, setDisplayAll] = useState(true);
  const [loading, setLoading] = useState(false);
  const [mapZoom, setMapZoom] = useState(5);
  const [usingSearch, setUsingSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [error, setError] = useState('');
  const [clinicSelected, setClinicSelected] = useState({});
  console.log('error: ', error);

  const clinicProviders = useMemo(() => {
    const providers = [];
    Object.values(props.partnerClinics)?.forEach(clinic => {
      if (!providers.includes(clinic.phlebotomyProviderName)) {
        providers.push(clinic.phlebotomyProviderName);
      }
    });
    
    return providers;
  }, [props.partnerClinics]);

  const [clinicProviderSelected, setClinicProviderSelected] = useState(clinicProviders)
  const autocomplete = useRef(null);

  const mapStyles = {
    width: '100%',
    height: '350px', 
  }; 
  let align; 
  if (longitudeSearch && latitudeSearch && displayAll) {
    align = "align-items-center";
  } else {
    align = "align-items-top";
  }
 
  const distance = (lat1, lon1, lat2, lon2, unit) => {
    var radlat1 = Math.PI * lat1/180
    var radlat2 = Math.PI * lat2/180
    var theta = lon1-lon2
    var radtheta = Math.PI * theta/180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist)
    dist = dist * 180/Math.PI
    dist = dist * 60 * 1.1515
    if (unit=="K") { dist = dist * 1.609344 }
    if (unit=="N") { dist = dist * 0.8684 }
    return dist
  }
 
  const _mapLoaded = (mapProps, map) => {
    const mapStyle = [ 
      {
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#ECE7E5"
          }
        ]
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#2d2d2c"
          }
        ]
      },
      {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#ffffff"
          }
        ]
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#ffffff"
          }
        ]
      },
      {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#C8C3C1"
          }
        ]
      } 
    ]  
    map.setOptions({
      styles: mapStyle
    })
  }
 
  const renderAutoComplete = (e) => {
    e.preventDefault();
    let value = autocomplete.current.value; 

    // if(clinicProviderSelected.length === 0){
    //   setError("You must select at least one provider")
    //   return;
    // }

    if(value.length === 0){
      setError("You must enter a location")
      return;
    }

    setLoading(true);

    Geocode.fromAddress(value).then(
      response => {
        const { lat, lng } = response.results[0].geometry.location;
        setLongitudeSearch(lng);
        setLatitudeSearch(lat);
        setUseCurrentLocation(false);
        setLoading(false);
        setDisplayAll(false);
        setMapZoom(9);
        setUsingSearch(true);
        setError('');
        setSearchValue(value);
      },
      error => {
        setShowingInfoWindow(false);
        setActiveMarker({});
        setSelectedPlace({});
        setUseCurrentLocation(false);
        setPosition(null);
        setLongitudeSearch('-2.43');
        setLatitudeSearch('53.34');
        setDisplayAll(true);
        setLoading(false);
        setMapZoom(5);
        setUsingSearch(false);
        setSearchValue('');
        setError('We were unable to find any results. Please try again.'); 
      });
 
  }

  const useLocation = () => {
    setLoading(true); 
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
        setUseCurrentLocation(true);
        setLoading(false);
        setLongitudeSearch(position.coords.longitude);
        setLatitudeSearch(position.coords.latitude);
        setUsingSearch(true);
        setDisplayAll(false);
        setUseCurrentLocation(false);
        setMapZoom(9);
        setSearchValue("My current location");
        setError(''); 
      }, function(error) {
        setShowingInfoWindow(false);
        setActiveMarker({});
        setSelectedPlace({});
        setUseCurrentLocation(false);
        setPosition(null);
        setLongitudeSearch('-2.43');
        setLatitudeSearch('53.34');
        setDisplayAll(true);
        setLoading(false);
        setMapZoom(5);
        setUsingSearch(false);
        setSearchValue('');
        setError('We were unable to find any results. Please try again.')       
      },{timeout:5000});
    } else {
      setShowingInfoWindow(false);
      setActiveMarker({});
      setSelectedPlace({});
      setUseCurrentLocation(false);
      setPosition(null);
      setLongitudeSearch('-2.43');
      setLatitudeSearch('53.34');
      setDisplayAll(true);
      setLoading(false);
      setMapZoom(5);
      setUsingSearch(false);
      setSearchValue('');
      setError('Your device does not support geolocation. Please try using the search box above.')
    }

  }

  const clearSearch = () => {
    setShowingInfoWindow(false);
    setActiveMarker({});
    setSelectedPlace({});
    setUseCurrentLocation(false);
    setPosition(null);
    setLongitudeSearch('-2.43');
    setLatitudeSearch('53.34');
    setDisplayAll(true);
    setLoading(false);
    setMapZoom(5);
    setUsingSearch(false);
    setSearchValue('');
    setError('')
  }
 
  const displayMarkers = () => { 
    // if(!error && clinicProviderSelected.length == 0){
    //   setError("You must select at least one provider")
    //   return;
    // }

    const partnerClinics = props.partnerClinics; 
    let count = 0;
    let iconType;
    if (global.config.websiteId == 1) { 
      iconType = '';
    } else {
      iconType = '';
    }
    
    const clinics = orderBy(partnerClinics, clinic=>{
      return distance(clinic.latitude, clinic.longitude, latitudeSearch, longitudeSearch, "N")
    }, 'asc').filter(clinic => clinicProviderSelected.includes(clinic.phlebotomyProviderName));


    if (longitudeSearch && latitudeSearch && !displayAll) {

      return clinics.map(function(clinic) {

        if (distance(clinic.latitude, clinic.longitude, latitudeSearch, longitudeSearch, "N") <= 30 && count <=4) {
          count++;

          return ( 
            <Marker key={clinic.iD} id={clinic.iD} position={{
             lat: clinic.latitude,
             lng: clinic.longitude
            }}
            icon={{
              url: "/img/mapIconBlue"+count+".png" 
            }}
            onClick={onMarkerClick.bind(this)} />
          ) 
        } 
      });

    } else {
      return Object.keys(partnerClinics).map(function(key) {

        return (
          <Marker key={key} id={key} position={{
           lat: partnerClinics[key].latitude,
           lng: partnerClinics[key].longitude
          }}
          icon={{
            url: "/img/mapIconBlue.png" 
          }}
          onClick={onMarkerClick.bind(this)} />
        )
      });
    } 
  }

  const handleClinicSelected = (clinic) => {
    setClinicSelected(clinic)
    props.setStep(2)
  } 

  const displayMarkerInfo = () => { 
 
    const partnerClinics = props.partnerClinics;
     
    let results = [];
    let count = 0;
    let distanceVar;
    let iconType;
    if (global.config.websiteId == 1) { 
      iconType = '';
    } else {
      iconType = '';
    }

    if (longitudeSearch && latitudeSearch && !displayAll) {

      const clinics = orderBy(partnerClinics, clinic=>{
        return distance(clinic.latitude, clinic.longitude, latitudeSearch, longitudeSearch, "N")
      }, 'asc').filter(clinic => clinicProviderSelected.includes(clinic.phlebotomyProviderName));

      clinics.map(function(clinic, index) {
        distanceVar = distance(clinic.latitude, clinic.longitude, latitudeSearch, longitudeSearch, "N");
        if ( distanceVar <= 30 && count <=4) { 
          count++;
          results.push( 
            <div key={clinic.iD} id={"clinic"+clinic.iD} className="partnerClinic py-4">
              <div className="row g-0">
                <div className="col-2">
                  <img src={"/img/mapIconBlue"+iconType+count+".png"} />
                </div> 
                <div className="col-8 text-start">
                  <h4 className='text-balance'>{clinic.clinicName}</h4>
                  <div className="distance mb-3">Distance - {distanceVar.toFixed(2)} miles</div> 
                  <h5>Address</h5>
                  <div className="address">{clinic.clinicAddress}</div>
                </div> 
                <div className="col-2 align-self-center">
                  <Button className={`${styles.rounded}`} onClick={() => handleClinicSelected({...clinic, clinicNumber: index+1})}>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Button>
                </div> 
              </div> 
            </div> 
          )
        }
      });


      return (
        
        <div id="partnerClinicSearchResults">
          <RenderIf isTrue={props.step == 1}>
            <div className="resultsTitle mt-4 resultsTitle pb-2 text-center">
              <p className="m-0 mb-2">We have found <strong>{count}</strong> clinics near you</p>
              <p className="mb-4 text-center text-danger">Please note you will only be able to book an appointment after you have completed your purchase. You will receive an email with instructions on how to do so.</p>
            </div>
            
            <div className="clinicResults">  
              {results} 
            </div>
          </RenderIf>

          <RenderIf isTrue={props.step == 2}>
            <RenderIf isTrue={!isEmpty(clinicSelected)}>
              <div className="clinicResults">
                <div className="partnerClinic py-4">
                  <div className="row g-0">
                    <div className="col-2">
                      <img src={"/img/mapIconBlue"+clinicSelected.clinicNumber+".png"} />
                    </div> 
                    <div className="col-10 text-start">
                      <h4>{clinicSelected.clinicName}</h4>
                      <div className="distance mb-3">Distance - {distance(clinicSelected.latitude, clinicSelected.longitude, latitudeSearch, longitudeSearch, "N")?.toFixed(2)} miles</div> 
                      <h5>Address</h5>
                      <div className="address">{clinicSelected.clinicAddress}</div>
                    </div>
                  </div> 
                </div>
              </div>
            </RenderIf>

            <p className="lh-base text-pretty text-center my-4">This clinic service is provided by {clinicSelected.phlebotomyProviderName}. Upon completing your order, you will receive an email with instructions on how to book your appointment.</p>
            <RenderIf isTrue={clinicSelected.phlebotomyProviderInstructions}>
              <p className="lh-base text-pretty text-center my-4">{clinicSelected.phlebotomyProviderInstructions}</p>
            </RenderIf>

            <div className="row gy-3 justify-content-center pt-3">
              <div className="col-10">
                <Button data-bs-dismiss="modal" aria-label="Close" className={"w-100 h-100 text-center"} onClick={(e) => updatePhlebotomyOption(e)}>Add clinic appointment £{props.phlebotomyPrice?.replaceAll(".00", "")}</Button>
              </div>
              <div className="col-10">
                <Button variant="link" className={"h-100 w-100 text-center"} onClick={() => props.setStep(1)}>cancel</Button>
              </div>
            </div>
          </RenderIf>
        </div>

      )

    } else {
      return '';
    }

  }

  const updatePhlebotomyOption = (e) => {
    props.updateToPhlebotomyClinic(5)
    props.setStep(1) 
    props.changeScreen(e, 1)
  }

  const onMarkerClick = ( props, marker, e) => { 
    setSelectedPlace(props.partnerClinics[marker.id]);
    setActiveMarker(marker);
    setShowingInfoWindow(true); 
  } 
 
  const handleSelectProvider = (isChecked, provider) => {
    const newClinicProviderSelected = isChecked
      ? [...clinicProviderSelected, provider]
      : clinicProviderSelected.filter(p => p !== provider);

    setClinicProviderSelected(newClinicProviderSelected);
  };

  const handleSelectAllProviders = () => {
    setClinicProviderSelected(clinicProviders);
  };

  const handleEmptyAllProviders = () => {
    setClinicProviderSelected([]);
  };

  return ( 
    <div id={styles.partnerClinicFinder}> 
      <div className="row">
        <div className="col-12 text-center">
          <h1 className="mb-3">Find a clinic</h1>
        </div>
      </div>
      <div className={"row "+align}> 
        {(() => {
          if (loading) { 
            return ( 
              <div className="col-12 text-center pt-4">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>   
            )
          } else { 
            return ( 
              <React.Fragment>
                <div className="col-12 mt-md-5 mb-4 mb-md-5 mt-lg-0 mb-lg-0">
                  <div className={styles.partnerClinicSearch}>
                    {longitudeSearch && latitudeSearch && displayAll ? (
                      <React.Fragment>
                        <p className="mb-4 text-center">{isEdge ? "EDGE" : "FORTH"} has partnered with Health Clinics across the UK to make sample collecting simple and convenient.</p>
                        <p className="mb-4 text-center text-danger">Please note you will only be able to book an appointment after you have completed your purchase. You will receive an email with instructions on how to do so.</p>
                        {/* <RenderIf isTrue={clinicProviders.length > 0}>
                          <div className='col-12'>
                            <h4>
                              Clinic providers
                              <RenderIf isTrue={clinicProviders.length !== clinicProviderSelected.length}>
                                <Button
                                  type="button"
                                  variant="link"
                                  className={styles.selectAllBtn}
                                  onClick={handleSelectAllProviders}>
                                  Select all
                                </Button>
                              </RenderIf>
                              <RenderIf isTrue={clinicProviders.length === clinicProviderSelected.length}>
                                <Button
                                  type="button"
                                  variant="link"
                                  className={styles.selectAllBtn}
                                  onClick={handleEmptyAllProviders}>
                                  Clear all
                                </Button>
                              </RenderIf>
                            </h4>
                          </div>

                          <div className={`col-12 d-flex ${styles.providersList} ${clinicProviders?.length > 4 ? "gap-3" : "gap-2"}`}>
                            {clinicProviders?.map(clinic => {
                              const isChecked = clinicProviderSelected.includes(clinic);

                              return (
                                <ProviderPill className="col-auto" providerName={clinic} checked={isChecked} handleSelect={handleSelectProvider}>
                                  {clinic}
                                </ProviderPill>
                              );
                            })}
                          </div>
                        </RenderIf> */}
                        <form onSubmit={renderAutoComplete} className={styles.searchForm+" justify-content-center align-items-center form-inline active-purple-3 active-purple-4 d-flex"}>
                          <input className="form-control-sm me-2" type="text" placeholder="Enter a location" ref={autocomplete} />
                          <div className={styles.buttonContainer}>
                            <button className="btn submitClinicSearch" type="submit"><FontAwesomeIcon icon={faSearch} aria-hidden="true"/></button>
                          </div> 
                        </form> 
                        <button onClick={useLocation} className={styles.useLocation+"  btn my-2 border-0"}><FontAwesomeIcon icon={faLocationArrow} className='me-2' /> Use my location</button>
                      </React.Fragment> 
                    ) : props.step != 2 ? ( 
                      <div onClick={clearSearch} className={styles.searchForm+" justify-content-center align-items-center form-inline active-purple-3 active-purple-4 d-flex"}> 
                        <input disabled className="form-control-sm me-2" type="text" placeholder="Enter a location" ref={autocomplete} value={searchValue}/> 
                        <div className={styles.buttonContainer}>
                          <button className="btn submitClinicSearch" type="submit"><FontAwesomeIcon icon={faTimes} aria-hidden="true"/></button>
                        </div>
                      </div>
                    ) : null} 
                  </div>
                  {displayMarkerInfo()} 
                  {error &&
                    <div className="alert alert-danger mt-3" role="alert">
                      {error}
                    </div>
                  } 
                </div> 
                <div className="col-12">
                  {useCurrentLocation && !usingSearch &&
                    <div className={styles.mapContainer}>
                      {selectedPlace.clinicAddress}
                      <Map
                        onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
                        centerAroundCurrentLocation 
                        google={props.google}
                        zoom={10}
                        style={mapStyles} 
                      >
                        {displayMarkers()}
                        <InfoWindow
                          marker={activeMarker}
                          visible={showingInfoWindow}>
                            <div>
                              <p>{selectedPlace.clinicAddress}</p>
                            </div>
                        </InfoWindow>
                      </Map>
                    </div>
                  } 

                  {longitudeSearch && latitudeSearch && !useCurrentLocation && props.step != 2 &&
                    <div className={"my-4 "+styles.mapContainer}>
                      <Map 
                        onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
                        google={props.google}
                        zoom={mapZoom}
                        style={mapStyles}
                        initialCenter={{ lat: latitudeSearch, lng: longitudeSearch}}
                      >
                        {displayMarkers()}
                        <InfoWindow
                          marker={activeMarker}
                          visible={showingInfoWindow}>
                            <div>
                              <p>{selectedPlace.clinicAddress}</p>
                            </div>
                        </InfoWindow>
                      </Map>
                    </div>
                  } 
                </div> 
              </React.Fragment> 
            )
          }
        })()} 
      </div> 
    </div> 
  ) 
}
 
export default GoogleApiWrapper({
  apiKey: 'AIzaSyDE6jdIQJ3Vcm4-4pz1mTM39ZDVG-SJ5gQ',
  libraries: ['places']
})(PartnerClinicFinderInline);
