import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, Link, withRouter } from 'react-router-dom'
import { logout } from '../../actions/authentication-actions'
import PartnerHeaderNotifications from "./PartnerHeaderNotifications";
import { loadPartnerDetails, getPartnerOrderDetails, getPartnerCustomerDetails, getPartnerResults } from '../../actions/partners-actions'
import MobileMenu from './MobileMenu';
import NewUpdates from './NewUpdates/NewUpdates';
import RenderIf from '../RenderIf/RenderIf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';

class PartnerHeader extends Component {

  constructor(props) {
    super(props); 

    this.state = {
      logo: "",
      requestCode: ""
    }
  }

  componentWillMount() {
    let page = this;
    this.props.getPartnerCustomerDetails('me')
    this.props.getPartnerResults('me')
    this.props.loadPartnerDetails('me').then(function(response) {
      if(!response.error){
        page.setState({ 
          loading: false,
          logo: response.response.entities.partner[0].partnerLogo,
          partnerName: response.response.entities.partner[0].partnerName
        })
      }

    });
  }

 

  componentWillUpdate(nextProps){
    if (this.props.partner != nextProps.partner) {
      if(Object.keys(this.props.partner).length > 0 && !this.state.requestCode){
        this.setState({
          requestCode: this.props.partner[0].requestCode
        })
      }
    }

     
    if (nextProps.partner[0]) { 
      if (nextProps.partner[0].partnerLogo !== this.state.logo) {
        this.setState({ logo: nextProps.partner[0].partnerLogo });
      }
    }
   
  }


  logout = () =>{
    this.props.logout()
    this.props.history.push('/');
  }


  render() {
    const { logo, requestCode, partnerName } = this.state
    const { partner } = this.props
    const hasAccessToShockedKits = partner[0]?.showStockedKits == 1 ? true : false

    let commissionOrders = Object.values(this.props.partnerOrders).filter(order => {
      return (
        order.partnerCommisionId == this.props.partner[0].partnerId && order.commissionAmount > 0 
      )
    })

    return (  
      <>
      <div id="partnerHeader">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid g-0">
            <div className="col-lg-3 col-md-4 col-9 position-relative">
              <div className="navbar-brand d-flex align-items-center justify-content-evenly py-0 mx-0">
                <Link to="/" className="col-3 text-center">
                  <img src={logo ? logo : "/img/forthEdgeLogo.jpeg"} className="my-2 rounded" width="50px"></img>
                </Link>
                <div className="dropdown dropdownHeader col-7">
                  <div className="dropdown-toggle text-brand text-start position-relative d-flex align-items-center cursor-pointer" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <p className="m-0" dangerouslySetInnerHTML={{ __html: partnerName || "Company Name" }}></p> <span className={`chevrot-icon ${partnerName?.length > 15 && partnerName?.length <= 22 ? "top-15" : partnerName?.length > 22 && "top-25"}`}></span>
                  </div>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li>
                        <NavLink exact activeClassName="active" className="dropdown-item" to="/partner-account">My account</NavLink>
                    </li>
                    <li>
                        <NavLink exact activeClassName="active" className="dropdown-item" to="/partner-settings">Company settings</NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/partner-tests" isActive={(match, location) => location.pathname.split("/").includes('partner-tests')} className="dropdown-item" >Tests</NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/partner-users" isActive={(match, location) => location.pathname.split("/")[1].includes("user")} className="dropdown-item">Users</NavLink>
                    </li>
                    {(this.props.partner[0]?.partnerCommission != 0 || commissionOrders?.length > 0) && <li>
                        <NavLink exact to="/partner-commission" isActive={(match, location) => location.pathname.split("/")[1].includes('commission')} className="dropdown-item">Commission</NavLink>
                    </li>}
                    <li onClick={this.logout}>
                        <NavLink exact activeClassName="active" className="dropdown-item" to="/">Sign out of Forth</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            

            <div className="collapse navbar-collapse col-lg-6 justify-content-center" id="navbarNavAltMarkup">
              <div className="navbar-nav col-9 justify-content-around align-items-center">
                <div className=" text-center">
                  <NavLink exact activeClassName="active" className="nav-link" to="/">Dashboard</NavLink>
                </div>
                <div className=" text-center">
                  <NavLink exact activeClassName="active" className="nav-link" to="/partner-orders">Orders</NavLink>
                  <span className="notif-num"></span>
                </div>
                <RenderIf isTrue={hasAccessToShockedKits}>
                  <div className=" text-center">
                    <NavLink exact activeClassName="active" className="nav-link" to="/stocked-kits">Stocked kits</NavLink>
                    <span className="notif-num"></span>
                  </div>
                </RenderIf>
                <div className=" text-center">
                  <NavLink exact to="/partner-results" isActive={(match, location) => location.pathname.split("/").includes('partner-results')} className={"nav-link"}>Results</NavLink>
                  <span className="notif-num"></span>
                </div>
                <div className=" text-center">
                  <NavLink exact to="/partner-clients" isActive={(match, location) => location.pathname.split("/").includes('partner-clients')} className={"nav-link"}>Clients</NavLink>
                </div>
                {/*
                <div className=" text-center">
                  <a className="nav-link" href="#">Help Guides</a>
                </div>
                */}
              </div>
            </div>
            
            {this.props.partner[0]?.requestCode && <div className="col-lg-2 col-md-6 col-2">
              <div className="row w-100 align-items-center justify-content-md-end justify-content-center flex-nowrap">
                
                <a target="_blank" className="text-center w-auto newUpdatesButton me-2" href="https://www.forthconnect.io/support/">
                  <FontAwesomeIcon icon={faQuestion} className='fa-fw'/>
                </a>

                <NewUpdates hasNewUpdates={this.props.user.hasNewUpdates} />
                {/* <PartnerHeaderNotifications /> */}
                <div className="text-center d-none d-md-block ps-3 w-auto">
                  {/* Change partnerShop URL */}
                  <a href={`https://biomarkertracking.com/${requestCode}`} target="_blank" className="btn btn-shop dark-padding">Your Shop <FontAwesomeIcon icon={faExternalLink} className='ms-2' /></a>
                </div>
              </div>
            </div>}
          </div>
        </nav>
          {this.props.partner[0].demoAccount == 1 && 
        <div>
          <div className="alert alert-warning m-0 rounded-0" role="alert">
            <span>You are currenty logged into a demo account.</span>
          </div>
        </div>
        }
      </div>

      <div id="mobileMenu">
        <MobileMenu />
      </div>
    </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {

  const { 
    entities:{partner, partnerOrders},
    authentication, 
    user
  } = state
 

  return {
    authentication, 
    user,
    partner,
    partnerOrders
  }
}

export default withRouter(connect(mapStateToProps, { 
  logout,
  loadPartnerDetails, 
  getPartnerOrderDetails, 
  getPartnerCustomerDetails, 
  getPartnerResults
})(PartnerHeader))
